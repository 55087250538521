// Hack für jquery.ajax-filtering
window.__AJAXIFY__PERSISTENT__REGISTERED = false;

import 'custom-event-polyfill';

import './vendor/jquery';
import './vendor/bootstrap';
import './vendor/select2';
import 'jquery.scrollto/jquery.scrollTo.js';
import 'jquery-placeholder/jquery.placeholder.js';
import 'jquery.cookie/jquery.cookie.js';
import 'match-media/matchMedia.js';
import 'match-media/matchMedia.addListener.js';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import '@kdose/symfony-bootstrap/js/utils';
import '@kdose/symfony-bootstrap/js/jquery-utils/jquery.stringtodate';
import './utils/bootstrap-init.js';
import './utils/confirm';

import './global.js';
